import React from 'react'
import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import EventsImg from '../../static/img/Events.png'
import Content, { HTMLContent } from '../components/Content'
import XMark from '../../static/img/XMark.png'

export const EventsPageTemplate = ({
  backgroundimage,
  events,
  contentComponent
}) => {
  const PageContent = contentComponent || Content
  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      {/* BACKGROUND HEADER IMAGE */}
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >
        <div
          style={{
            display: 'flex',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <img src={EventsImg} alt="Title" style={{ height: '300px' }} />
        </div>
      </div>

      {/* EVENT LIST */}
      { events.length > 0 ?
        <section className='section'>
          <div className='container'>
            <div className='content'>
              <div className='columns is-multiline' style={{

              }}>
                {events.map((item) => (
                  <div className='event-pane-box'>
                    <h1 className="title is-size-3 has-text-weight-bold is-bold-light"
                      style={{
                        marginTop: '0px',
                        marginBottom: '5px',
                        color: '#B77D2C',
                        
                        // fontSize: '2.4em',
                        // color: 'white',
                        // fontFamily: 'IM Fell English'
                      }}
                    >
                      {item.name}
                    </h1>
                    <div className='event-pane'>
                      <div style={{
                        minWidth: '300px',
                      }}>
                        <p className='event-pane-date' style={{fontWeight: 'normal', fontSize: '20px', marginBottom: '10px'}}>{item.date} {item.time === null ? null : item.time}</p>
                        {item.image === null || item.image === undefined ? <div/> : 
                          <img className='event-pane-image' src={item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image} alt="Image" />
                        }
                      </div>

                      <div className='event-pane-text'>
                        <PageContent className="content" content={toHTML(item.description)} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section >
      :
        <div className='no-events'>
          {/* Google Fonts Import */}
          <link
            href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
            rel="stylesheet"
          ></link >
          <img src={XMark} className='x-mark' />
          <p>
            There are no<br></br>events to show<br></br>at this time.<br></br><br></br>Stay tuned.
          </p>
        </div>
      }
    </div>
  )
}

EventsPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  events: PropTypes.array,
  contentComponent: PropTypes.func,
}

const EventsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <EventsPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        events={frontmatter.events}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default EventsPage

export const EventsPageQuery = graphql`
  query EventsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "events-page" } }) {
      html
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        events {
          name
          date
          time
          image {
            childImageSharp {
              fluid(maxWidth: 1075, quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`
